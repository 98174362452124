import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
    console.log = function () {};
}

const App = (): React.ReactElement => {
    return (
        <Router>
            <Layout />
        </Router>
    );
};

export default App;
