import { Book, Instagram, YouTube, Videocam, Facebook } from '@mui/icons-material';

export const launcherItems = [
    {
        title: 'LimaNovember.Aero',
        link: 'https://limanovember.aero/',
        icon: Book,
        description: '',
        tags: ['Pilotblogg']
    },
    {
        title: '@thomfregram',
        link: 'https://www.instagram.com/thomfregram/',
        icon: Instagram,
        description: '',
        tags: ['Instagram']
    },
    {
        title: 'Thomas Fredriksen',
        link: 'https://www.youtube.com/channel/UCYNfHGrvOnH9xs7fZst_9Hg',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: '@pilatusnorway',
        link: 'https://www.instagram.com/pilatusnorway/',
        icon: Instagram,
        description: '',
        tags: ['Instagram']
    },
    {
        title: 'Frank Olaf Sem-Jacobsen',
        link: 'https://www.youtube.com/c/FrankOlafSemjacobsen',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'Thomas Jamne',
        link: 'https://www.youtube.com/channel/UCcOs7mogczYVrprVK-Vqg5w',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'Asbjørn Rugnes',
        link: 'https://www.youtube.com/channel/UCFI2pnR2PM1T3c8N1YoEXrg',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'LifeOfMagnus',
        link: 'https://www.youtube.com/channel/UCdrKHs3SWDG_bde5NmHJzTg',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'TheSunnAir',
        link: 'https://www.youtube.com/user/TheSunnAir',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'SunnAir.no',
        link: 'https://www.sunnair.no/',
        icon: Book,
        description: '',
        tags: ['Pilotblogg']
    },
    {
        title: 'Olavb1',
        link: 'https://www.youtube.com/user/Olavb1',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'Pål S. Jensen',
        link: 'https://vimeo.com/user16888080',
        icon: Videocam,
        description: '',
        tags: ['Vimeo']
    },
    {
        title: 'avi8 studio',
        link: 'https://www.youtube.com/channel/UCkaBjaukaPuJryiyB68r4iw',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'Vindskeiveluftvegar',
        link: 'https://www.youtube.com/channel/UCmRHWKDGMpcyh104kbMb9Zw',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'TommyPilot',
        link: 'https://www.youtube.com/user/TommyPilot06',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: '@tommypiloten',
        link: 'https://www.instagram.com/TommyPiloten/',
        icon: Instagram,
        description: '',
        tags: ['Instagram']
    },
    {
        title: 'TommyPiloten',
        link: 'https://www.facebook.com/tommypiloten',
        icon: Facebook,
        description: '',
        tags: ['Facebook']
    },
    {
        title: 'LimaNovember.Aero',
        link: 'https://www.facebook.com/LimaNovember.Aero/',
        icon: Facebook,
        description: '',
        tags: ['Facebook']
    },
    {
        title: 'Plane Geevz',
        link: 'https://www.youtube.com/channel/UCLC9Yx5tCGGy6xCmnivukKA',
        icon: YouTube,
        description: '',
        tags: ['YouTube']
    },
    {
        title: 'Skyhøyt!',
        link: 'https://flyskyhoyt.wordpress.com/',
        icon: Book,
        description: '',
        tags: ['Pilotblogg']
    }];