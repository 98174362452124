import CheckIcon from '@mui/icons-material/Check';
import { Chip, Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { setTitle } from '../../tools/SetTitle';
import { uniqueValues } from '../../tools/UniqueValues';
import { launcherItems } from './LauncherItems';

const Home = (): React.ReactElement => {
    const theme = useTheme();

    useEffect(() => {
        setTitle('Lenker');
    }, []);

    const uniqueTags = useMemo(() => launcherItems.flatMap((i) => i.tags).filter(uniqueValues), [launcherItems]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const sortedLauncherItems = useMemo(
        () =>
            launcherItems
                .filter((i) => selectedTags.length === 0 || i.tags.filter((it) => selectedTags.includes(it)).length > 0)
                .sort((a, b) => a.title.localeCompare(b.title)),
        [launcherItems, selectedTags]
    );

    const getColor = (item: any): 'inherit' | 'disabled' | 'action' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' => {
        if (item.tags.includes('YouTube')) {
            return 'secondary';
        }

        if (item.tags.includes('Instagram') || item.tags.includes('Vimeo') || item.tags.includes('Facebook')) {
            return 'primary';
        }

        return 'inherit';
    };

    const tagIsSelected = (tag: string): boolean => {
        return selectedTags.includes(tag);
    };

    const toggleTag = (tag: string) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags([...selectedTags.filter((t) => t !== tag)]);
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    return (
        <>
            <Paper elevation={0} sx={{ padding: theme.spacing(2) }}>
                <Typography variant="h6" sx={{ marginTop: theme.spacing(-1), paddingBottom: theme.spacing(1) }}>
                    Filter
                </Typography>
                <Stack direction="row" spacing={theme.spacing(1)}>
                    {uniqueTags.map((t) => (
                        <Chip
                            label={t}
                            clickable
                            onClick={() => toggleTag(t)}
                            icon={tagIsSelected(t) ? <CheckIcon /> : undefined}
                            color={tagIsSelected(t) ? 'success' : undefined}
                        />
                    ))}
                </Stack>
            </Paper>
            <Grid container>
                {sortedLauncherItems.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} padding={theme.spacing(3)}>
                        <Card
                            onClick={() => window.open(item.link, '_blank')}
                            sx={{
                                cursor: 'pointer',
                                ':hover': {
                                    boxShadow: theme.shadows[20]
                                },
                                width: '100%'
                            }}>
                            <CardContent>
                                <Box alignItems="center" justifyContent="center" height={200} display="flex" flexDirection="column">
                                    <Icon component={item.icon} fontSize="large" color={getColor(item)} />
                                    <Typography variant="h5">{item.title}</Typography>
                                    <Typography variant="body2">{item.description}</Typography>
                                    <Stack sx={{ marginTop: theme.spacing(1) }}>
                                        {item.tags.map((t) => (
                                            <Chip label={t} color="info" />
                                        ))}
                                    </Stack>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Home;
