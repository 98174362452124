import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { default as React } from 'react';
import packageJson from '../../package.json';

const Footer = (): React.ReactElement => {
    const theme = useTheme();

    return (
        <footer style={{ flexShrink: 0, padding: theme.spacing(2, 4), backgroundColor: theme.palette.grey[300] }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="subtitle1" align="left"></Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" align="right" color="textSecondary" component="p">
                        Version {packageJson.version}{' '}
                    </Typography>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;
