import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Home from './home/Home';

const Routes = (): React.ReactElement => {
    return (
        <RouterRoutes>
            <Route path="/" element={<Home />} />
        </RouterRoutes>
    );
};

export default Routes;
